import App from '../app';

class SignupApp extends App {
  registerViews(AppLoader) {
    super.registerViews(AppLoader);
    import(/* webpackChunkName: "leads" */ '../shared/leads').then(leadsModule => leadsModule.createLead());
  }
}

new SignupApp().start();
